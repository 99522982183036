<template>
    <v-card outlined class="pa-5 mt-5">
        <v-card-title>Address information</v-card-title>
        <v-card-text>
            <v-autocomplete
            v-model="country"
            :rules="[v => !!v || 'This field is required']"
            :items="countries"
            item-text="name"
            item-value="code"
            placeholder="Country"
            name="country"
            required
            outlined
            prepend-inner-icon="mdi-flag"
            hide-no-data
            color="blue"
            autocomplete="off"
            id="address-country"
            ></v-autocomplete>
            <vue-google-autocomplete
            ref="address"
            id="card-address"
            classname="address-autocomplete"
            placeholder="Please type your address"
            @placechanged="getAddressData"
            :country="country"
            required
            @focus="changeInputColor('card-address', 'blue')"
            >
            </vue-google-autocomplete>
            <v-text-field
            outlined
            v-model="city"
            :rules="[v => !!v || 'This field is required']"
            label="City"
            required
            color="blue"
            id="city"
            ></v-text-field>
            <v-text-field
            outlined
            v-model="state"
            :rules="[v => !!v || 'This field is required']"
            label="State / Governorate"
            required
            color="blue"
            id="state"
            ></v-text-field>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                outlined
                v-model="zip"
                :rules="[v => !!v || 'This field is required']"
                label="ZipCode"
                required
                color="blue"
                id="zip"
                ></v-text-field>
              </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { countries } from '../contants'

export default {
  components: {
    VueGoogleAutocomplete
  },
  data () {
    return {
      country: 'US',
      address: '',
      zip: '',
      writtenAddress: '',
      streetLine: '',
      city: '',
      state: ''
    }
  },
  watch: {
    zip (newVal) {
      this.$store.dispatch('setZipCode', newVal)
      this.$store.dispatch('setWrittenCountry', this.country)
    },
    city (newVal) {
      this.$store.dispatch('setWrittenCity', newVal)
      this.$store.dispatch('setWrittenCountry', this.country)
    },
    state (newVal) {
      this.$store.dispatch('setWrittenState', newVal)
      this.$store.dispatch('setWrittenCountry', this.country)
    },
    country (newVal) {
      this.$store.dispatch('setWrittenCountry', newVal)
    }
  },
  computed: {
    countries () {
      return countries
    }
  },
  methods: {
    changeInputColor (id, color) {
      document.getElementById('card-address').style.borderColor = color
    },
    getAddressData (addressData, placeResultData) {
      this.city = ''
      placeResultData.address_components.forEach(_item => {
        if (_item.types.indexOf('administrative_area_level_1') !== -1) {
          this.state = _item.long_name + ', ' + _item.short_name
        }
        if (_item.types.indexOf('locality') !== -1) {
          this.city = _item.short_name
        } else if (!this.city) {
          if (_item.types.indexOf('administrative_area_level_2') !== -1) {
            this.city = _item.short_name
          }
        }
      })
      if (addressData.country) this.country = countries.find(e => e.name === addressData.country).code
      this.address = `${placeResultData.formatted_address}`
      if (addressData.street_number) this.streetLine = `${addressData.street_number}`
      if (addressData.postal_code) this.zip = addressData.postal_code
      else this.zip = 12345
      let countryIso = countries.find(e => e.name === addressData.country).code
      if (!countryIso) countryIso = this.country
      this.$store.dispatch('setZipCode', this.zip)
      this.$store.dispatch('setWrittenCity', this.city)
      this.$store.dispatch('setWrittenState', this.state)
      this.$store.dispatch('setWrittenCountry', countryIso)
      this.$store.dispatch('setWrittenAddress', this.address)
    }
  },
  created () {
    this.$store.dispatch('setWrittenAddress', '')
    this.$store.dispatch('setZipCode', '')
  }
}
</script>

<style>
.address-autocomplete {
    width: 100%;
    height: 56px;
    outline: none;
    border: 0.5px solid #9e9e9e;
    border-radius: 5px;
    padding: 0 10px;
    margin-bottom: 30px;
}
</style>
